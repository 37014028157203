import React, {useRef, useState, useEffect} from 'react';
import Globe from 'react-globe.gl'
import logo from './logo.svg';
import './App.css';


const World = () => {
  const globeEl = useRef();
  const [countries, setCountries] = useState({ features: []});
  const [altitude, setAltitude] = useState(0.007);
  const [transitionDuration, setTransitionDuration] = useState(300);
  const [admin, setAdmin] = useState('');

  useEffect(() => {
    // load data
    fetch(process.env.PUBLIC_URL + 'countries.geo.json').then(res => res.json())
        .then(countries=> {
          setCountries(countries);

          // setTimeout(() => {
          //   setTransitionDuration(4000);
          //   // @ts-ignore
          //   setAltitude(() => feat => Math.max(0.1, Math.sqrt(+feat.properties.POP_EST) * 7e-5));
          // }, 3000);
        });
  }, []);

  useEffect(() => {
    // Auto-rotate
    // @ts-ignore
    globeEl.current.controls().autoRotate = false;
    // @ts-ignore
    globeEl.current.controls().autoRotateSpeed = 0.3;

    // @ts-ignore
    globeEl.current.pointOfView({ altitude: 4 }, 5000);
  }, []);

  return <Globe
      ref={globeEl}
      globeImageUrl="//unpkg.com/three-globe/example/img/earth-dark.jpg"

      // @ts-ignore
      polygonsData={countries.features.filter(d => d.properties.ISO_A2 !== 'AQ')}
      // @ts-ignore
      polygonAltitude={(d) => {console.log(d.properties); return d.properties.ADMIN === admin ? 0.02 : altitude}}
      // @ts-ignore
      polygonCapColor={(d) => {return `rgba(${d.properties.MAPCOLOR7*20}, 100, 100, 1.0)`}}
      polygonSideColor={() => 'rgba(255, 255, 255, 0.1)'}
      polygonStrokeColor={() => 'rgba(0,0,0,0.5)'}
      // @ts-ignore
      onPolygonHover={(d) => {try{setAdmin(d.properties.ADMIN)} catch(e) {setAdmin('')}}}
      // @ts-ignore
      polygonLabel={({ properties: d }) => `
        <b>${d.ADMIN} (${d.ISO_A2})</b> <br />
        Population: <i>${Math.round(+d.POP_EST / 1e4) / 1e2}M</i>
      `}
      polygonsTransitionDuration={transitionDuration}
  />;
};

function App() {
  return (
    <div className="App">
      <World />
    </div>
  );
}

export default App;
